<template>
  <BDropdown
    ref="dropdown"
    :text="text"
    boundary="viewport"
    variant="link"
    class="m-distribution-area-suggestion"
    toggle-class="text-primary p-0 text-decoration-none"
    menu-class="ml-n2 py-1 rounded-sm border-light shadow-sm overflow-auto"
    @hidden="loaded = false"
    @show="handleShow"
  >
    <BDropdownText v-if="!suggestions.length">
      <span v-if="!busy">{{ $t("No suggestion") }}</span>
      <BOverlay v-else :show="busy" size="sm" />
    </BDropdownText>
    <BDropdownText
      v-for="(suggestion, index) in suggestions"
      :key="index"
      class="m-distribution-area-suggestion-item f-flex justify-content-between"
      text-class="px-2"
      @click="handleClick(suggestion)"
    >
      <span href="#" class="text-decoration-none text-nowrap">
        <span class="text-primary">{{ suggestion.plz }}</span>
        <span v-if="suggestion.ort" class="ml-3 text-body">
          {{ suggestion.ort }}
        </span>
      </span>
    </BDropdownText>
  </BDropdown>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

import { SEARCH_SUGGESTION } from "@/store/action-types";

const { mapGetters, mapActions } = createNamespacedHelpers("distributionAreas");
const { mapFields } = createHelpers({
  getterType: "distributionAreas/getField",
  mutationType: "distributionAreas/updateField",
});

export default {
  name: "MDistributionAreaSuggestion",
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(["currentGeomCompositionIds", "newspapers"]),
    ...mapFields(["suggestions"]),
  },
  methods: {
    ...mapActions({ searchSuggestion: SEARCH_SUGGESTION }),
    handleShow(event) {
      if (!this.loaded) {
        event.preventDefault();
        this.loadItems();
      }
    },
    handleClick(item, event) {
      this.$emit("click-item", item, event);
    },
    loadItems() {
      this.busy = true;
      this.items = [];
      const params = {
        address: { plz: this.item.plz, ort: this.item.ort },
        excludeGeomCompositionsids: this.currentGeomCompositionIds,
        volume: this.newspapers.join(","),
      };
      this.searchSuggestion(params)
        .then((res) => {
          if (res && res.data && res.data.data) {
            this.suggestions = res.data.data;
          }
          this.busy = false;
          this.loaded = true;
          this.$refs.dropdown.show();
        })
        .catch(() => {
          this.busy = false;
        });
    },
  },
};
</script>
<style lang="scss">
.m-distribution-area-suggestion {
  .btn-link {
    font-size: 1rem;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/assets.scss";
.m-distribution-area-suggestion-item {
  border-bottom: 1px solid $light;
  cursor: pointer;
  &:last-of-type {
    border-bottom: none;
  }
}
</style>
