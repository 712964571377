<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.608 27.912H10.104C10.992 27.912 11.712 27.192 11.712 26.304C11.712 25.416 10.992 24.696 10.104 24.696H1.608C0.72 24.696 0 25.416 0 26.304C0 27.192 0.72 27.912 1.608 27.912Z"
      fill="currentColor"
    />
    <path
      d="M47.592 45.384L42.072 39.864C43.776 37.68 44.832 34.968 44.832 31.992C44.832 24.912 39.096 19.176 32.016 19.176C24.936 19.176 19.2 24.912 19.2 31.992C19.2 39.072 24.936 44.808 32.016 44.808C34.992 44.808 37.704 43.752 39.888 42.048L45.408 47.568C46.008 48.168 46.968 48.168 47.592 47.568C48.192 46.944 48.192 45.984 47.592 45.384ZM38.904 38.688C38.88 38.712 38.832 38.712 38.808 38.76C38.784 38.784 38.76 38.832 38.736 38.856C37.008 40.536 34.632 41.592 32.016 41.592C26.712 41.592 22.416 37.296 22.416 31.992C22.416 26.688 26.712 22.392 32.016 22.392C37.32 22.392 41.616 26.688 41.616 31.992C41.64 34.608 40.608 36.96 38.904 38.688Z"
      fill="currentColor"
    />
    <path
      d="M1.608 3.19203H39.168C40.056 3.19203 40.776 2.47203 40.776 1.58403C40.776 0.696028 40.056 -0.0239716 39.168 -0.0239716H1.608C0.72 2.84422e-05 0 0.720028 0 1.60803C0 2.49603 0.72 3.19203 1.608 3.19203Z"
      fill="currentColor"
    />
    <path
      d="M18.792 12.36H1.608C0.72 12.36 0 13.08 0 13.968C0 14.856 0.72 15.576 1.608 15.576H18.792C19.68 15.576 20.4 14.856 20.4 13.968C20.376 13.056 19.68 12.36 18.792 12.36Z"
      fill="currentColor"
    />
    <path
      d="M12.816 37.056H1.608C0.72 37.056 0 37.776 0 38.664C0 39.552 0.72 40.272 1.608 40.272H12.816C13.704 40.272 14.424 39.552 14.424 38.664C14.424 37.776 13.704 37.056 12.816 37.056Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ARowSearchIcon",
};
</script>

<style lang="scss" scoped></style>
