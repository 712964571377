<template>
  <div>
    <BSidebar
      id="mLeftSidebar"
      v-model="open"
      bg-variant="transparent"
      body-class="d-flex align-items-center"
      no-header
    >
      <slot />
      <ASidebarToggler
        v-if="open"
        key="close"
        v-b-toggle.mLeftSidebar
        :open="open"
        class="a-sidebar-toggler__inner"
      />
    </BSidebar>
    <ASidebarToggler
      v-if="!open"
      key="open"
      v-b-toggle.mLeftSidebar
      :open="open"
      class="a-sidebar-toggler__outer"
    />
  </div>
</template>

<script>
import { ASidebarToggler } from "@atoms";

export default {
  name: "MSidebar",
  components: { ASidebarToggler },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      open: this.visible || this.vale,
    };
  },
  watch: {
    open(newValue) {
      this.$emit("update:visible", newValue);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/assets.scss";

#mLeftSidebar {
  #app:not(.embeded) & {
    padding-bottom: var(--footer-height);
  }
}
#mLeftSidebar {
  // width: auto;
  width: $sidebar-width;
  .b-sidebar-body {
    padding-right: 30px;
  }
  @include media-breakpoint-up(laptop) {
    width: $sidebar-laptop-width;
  }
  @include media-breakpoint-up(desktop) {
    width: $sidebar-desktop-width;
  }
  // @include media-breakpoint-up(sm) {
  //   width: auto !important;
  //   .b-sidebar-body {
  //     padding-right: 30px;
  //     min-width: 576px;
  //   }
  // }
}
.a-sidebar-toggler__inner {
  right: 0;
  border-radius: $border-radius 0 0 $border-radius;
  z-index: 3;
  @include media-breakpoint-up(sm) {
    border-radius: 0 $border-radius $border-radius 0;
    z-index: 1;
  }
}
.a-sidebar-toggler__outer {
  border-radius: 0 $border-radius $border-radius 0;
}
</style>
