<template>
  <div>
    <OMap underlay />
    <OMediaPlanningHome />
    <OFooter />
  </div>
</template>

<script>
import { OFooter, OMap, OMediaPlanningHome } from "@organisms";

export default {
  name: "TWelcome",
  components: { OFooter, OMap, OMediaPlanningHome },
};
</script>

<style lang="scss" scoped></style>
