<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 47 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8705 33.2253C16.3918 33.2253 13.41 31.9632 10.941 29.4311C8.46409 26.9068 7.23353 23.862 7.23353 20.3044C7.23353 16.7468 8.47198 13.7019 10.941 11.1777C13.4179 8.65341 16.3918 7.3834 19.8705 7.3834C23.3492 7.3834 26.331 8.64552 28.8 11.1777C31.2769 13.7098 32.5075 16.7468 32.5075 20.3044C32.5075 23.862 31.269 26.9068 28.8 29.4311C26.3231 31.9632 23.3492 33.2253 19.8705 33.2253ZM45.9096 41.7052L36.2307 31.8133C38.5657 28.374 39.7331 24.5325 39.7331 20.3044C39.7331 17.5514 39.2125 14.9246 38.1634 12.4161C37.1142 9.90764 35.7101 7.74626 33.9274 5.92408C32.1525 4.10978 30.0306 2.66623 27.5773 1.60131C25.132 0.536401 22.5604 0 19.8705 0C17.1806 0 14.609 0.536401 12.1558 1.60131C9.70255 2.66623 7.58061 4.10978 5.80575 5.92408C4.0309 7.73837 2.6189 9.90764 1.56976 12.4161C0.520624 14.9246 0 17.5592 0 20.3044C0 23.0574 0.520624 25.6841 1.56976 28.1926C2.61101 30.7011 4.02301 32.8625 5.80575 34.6846C7.58061 36.4989 9.70255 37.9425 12.1558 39.0074C14.609 40.0723 17.1806 40.6087 19.8705 40.6087C24.0118 40.6087 27.7588 39.4176 31.127 37.0353L40.8059 46.9035C41.4843 47.6371 42.3283 48 43.3459 48C44.3241 48 45.1681 47.6371 45.886 46.9035C46.6038 46.1699 46.9588 45.3101 46.9588 44.3083C46.9588 43.2828 46.6117 42.4151 45.9096 41.7052Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ASearchIcon",
};
</script>

<style lang="scss" scoped></style>
