<template>
  <BCard no-body class="m-media-planning-card border-0 shadow bg-white">
    <BCardHeader
      v-if="$scopedSlots.header"
      class="bg-white border-bottom-0 py-3"
    >
      <slot name="header" />
    </BCardHeader>
    <slot name="beforeBody" />
    <BCardBody v-if="$slots.default" class="p-0" style="overflow-y: auto">
      <slot />
    </BCardBody>
    <slot name="afterBody" />
    <BCardFooter
      v-if="$scopedSlots.footer"
      class="bg-white text-center border-top-0 pt-3 pb-4"
    >
      <slot name="footer" />
    </BCardFooter>
  </BCard>
</template>

<script>
export default {
  name: "MMediaPlanningCard",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/molecules/MMediaPlanningCard.scss";
</style>
