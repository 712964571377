import { render, staticRenderFns } from "./OAreaLocator.vue?vue&type=template&id=c32d0678&scoped=true&"
import script from "./OAreaLocator.vue?vue&type=script&lang=js&"
export * from "./OAreaLocator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c32d0678",
  null
  
)

export default component.exports