<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.376 23.688H35.328C31.44 23.712 28.296 26.904 28.32 30.792C28.344 32.832 29.232 34.752 30.768 36.096L30.816 36.144L29.832 38.688H23.064L23.112 48H48.048L48 38.688H41.232L40.152 35.952L40.2 35.904C41.64 34.536 42.432 32.712 42.432 30.72C42.408 28.824 41.664 27.072 40.32 25.728C39 24.408 37.248 23.688 35.376 23.688ZM44.928 41.76L44.952 44.904H26.28V44.832H26.184L26.16 41.784H31.944L34.584 34.896L33.432 34.248C32.184 33.552 31.416 32.232 31.392 30.792C31.368 28.608 33.144 26.808 35.328 26.784H35.352C36.408 26.784 37.392 27.192 38.136 27.936C38.904 28.68 39.312 29.664 39.312 30.744C39.312 32.136 38.616 33.408 37.44 34.128L36.36 34.8L39.12 41.76H44.928Z"
      fill="currentColor"
    />
    <path
      d="M38.28 0H1.464C0.648 0 0 0.648 0 1.464V46.68C0 47.496 0.648 48.144 1.464 48.144H14.664C15.48 48.144 16.128 47.496 16.128 46.68V46.656C16.128 45.84 15.48 45.192 14.664 45.192H4.224C3.504 45.192 2.928 44.616 2.928 43.896V4.248C2.928 3.528 3.504 2.952 4.224 2.952H35.52C36.24 2.952 36.816 3.528 36.816 4.248V18.144C36.816 18.96 37.464 19.608 38.28 19.608C39.096 19.608 39.744 18.96 39.744 18.144V1.464C39.744 0.648 39.096 0 38.28 0Z"
      fill="currentColor"
    />
    <path
      d="M27.6 21.504H8.832C8.256 21.504 7.776 21.984 7.776 22.56V23.4C7.776 23.976 8.256 24.456 8.832 24.456H27.6C28.176 24.456 28.656 23.976 28.656 23.4V22.56C28.656 21.984 28.2 21.504 27.6 21.504Z"
      fill="currentColor"
    />
    <path
      d="M30.84 10.272H8.832C8.256 10.272 7.776 10.752 7.776 11.328V12.168C7.776 12.744 8.256 13.224 8.832 13.224H30.864C31.44 13.224 31.92 12.744 31.92 12.168V11.328C31.896 10.752 31.416 10.272 30.84 10.272Z"
      fill="currentColor"
    />
    <path
      d="M8.832 32.856C8.256 32.856 7.776 33.336 7.776 33.912V34.752C7.776 35.328 8.256 35.808 8.832 35.808H14.376C14.952 35.808 15.432 35.328 15.432 34.752V33.912C15.432 33.336 14.952 32.856 14.376 32.856H8.832Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "APageRowsStampIcon",
};
</script>

<style lang="scss" scoped></style>
