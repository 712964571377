<template>
  <div v-show="isActive" class="m-area-locator-tab p-3 mx-auto">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MAreaLocatorTab",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/assets.scss";

.m-area-locator-tab {
  @include media-breakpoint-up(desktop) {
    max-width: 720px;
  }
}
</style>
