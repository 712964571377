<template>
  <div>
    <OMap ref="map" v-bind="mapAttrs" :bounds.sync="bounds" />
    <MLeftSidebar :visible.sync="sidebarVisible">
      <OMediaPlanningSteps @step-changed="handleStepChanged" />
    </MLeftSidebar>
    <OAreaLocator v-if="showAreaLocator" v-show="!busy" />
    <BOverlay
      :show="busy"
      blur="1px"
      opacity="0.65"
      rounded="sm"
      variant="white"
      z-index="2000"
      no-wrap
    />
    <OFooter />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { mapFields } from "vuex-map-fields";
import { Icon, Marker } from "leaflet";
import { TOGGLE_ITEM } from "@/store/mutation-types";
import { SEARCH_AVAILABLE_DISTRIBUTION_AREAS } from "@/store/action-types";

import { MLeftSidebar } from "@molecules";
import { OFooter, OMap, OAreaLocator, OMediaPlanningSteps } from "@organisms";

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  "distributionAreas"
);
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "TMediaPlanning",
  components: {
    MLeftSidebar,
    OFooter,
    OMap,
    OAreaLocator,
    OMediaPlanningSteps,
  },
  provide() {
    return {
      mapFitBounds: this.mapFitBounds,
    };
  },
  data() {
    return {
      showAreaLocator: true,
      bounds: null,
      loadAvailableMarkersTimeout: null,
      busy: false,
      defaultMapAttrs: {
        underlay: true,
        zoomControl: true,
        zoom: 9,
      },
    };
  },
  computed: {
    ...mapFields(["sidebarVisible"]),
    ...mapGetters([
      "polygonGeoJson",
      "markersGeoJson",
      "availableMarkersGeoJson",
    ]),
    ...mapConfigGetters(["initialCenter", "initialZoom"]),
    markers() {
      if (this.availableMarkersGeoJson.length || this.markersGeoJson.length) {
        return {
          geojson: {
            type: "FeatureCollection",
            features: [...this.availableMarkersGeoJson, ...this.markersGeoJson],
          },
          options: {
            pointToLayer: this.handlePointToLayer,
            onEachFeature: this.handleEachFeature,
          },
        };
      }
      return null;
    },
    canvasMarkers() {
      if (this.availableMarkersGeoJson.length || this.markersGeoJson.length) {
        const markersGeoJson = [
          ...this.availableMarkersGeoJson,
          ...this.markersGeoJson,
        ];
        return markersGeoJson.map(({ geometry, properties }) => {
          const [lng, lat] = geometry.coordinates;
          const { item, iconUrl } = properties;
          const marker = new Marker([lat, lng], {
            icon: new Icon({
              iconUrl,
              iconSize: [48, 48],
              iconAnchor: [24, 24],
              tooltipAnchor: [20, -12],
            }),
          });
          marker.bindTooltip(`${item.plz} ${item.ort}`);
          marker.item = item;
          marker.handleClick = () => {
            this.toggleItem(item);
          };
          return marker;
        });
      }
      return null;
    },
    polygon() {
      if (this.polygonGeoJson.length) {
        return {
          geojson: {
            type: "FeatureCollection",
            features: this.polygonGeoJson,
          },
          options: {
            color: "#66A8CA",
          },
        };
      }
      return null;
    },
    mapAttrs() {
      return {
        ...this.defaultMapAttrs,
        center: this.initialCenter,
        zoom: this.initialZoom,
        // markers: this.markers,
        canvasMarkers: this.canvasMarkers,
        polygon: this.polygon,
      };
    },
  },
  watch: {
    bounds(newValue, oldValue) {
      this.loadAvailableMarkersWithTimeout(!oldValue);
    },
  },
  mounted() {
    this.$root.$on("loading", (busy) => {
      this.busy = busy;
    });
    this.$root.$on("map-center", (center) => {
      this.$refs.map.updateCenter(center);
    });
  },
  methods: {
    ...mapMutations({ toggleItem: TOGGLE_ITEM }),
    ...mapActions({
      loadAvailableMarkers: SEARCH_AVAILABLE_DISTRIBUTION_AREAS,
    }),
    handleStepChanged(currentStep) {
      this.showAreaLocator = currentStep === 1;
    },
    handlePointToLayer(feature, latLng) {
      const { item, iconUrl } = feature.properties;
      // const marker = L.canvasMarker(latLng, {
      //   radius: 20,
      //   img: {
      //     url: iconUrl,
      //   },
      // });
      const marker = new Marker(latLng, {
        icon: new Icon({
          iconUrl,
          iconAnchor: [24, 24],
          tooltipAnchor: [20, -12],
        }),
      });
      marker.on("click", () => this.toggleItem(item));
      return marker;
    },
    handleEachFeature(feature, layer) {
      const { item } = feature.properties;
      layer.bindTooltip(`${item.plz} ${item.ort}`);
    },
    mapFitBounds() {
      this.$refs.map.fitBounds();
    },
    loadAvailableMarkersWithTimeout(withOverlay = false) {
      if (this.loadAvailableMarkersTimeout) {
        clearTimeout(this.loadAvailableMarkersTimeout);
      }
      this.busy = true && withOverlay;
      this.loadAvailableMarkersTimeout = setTimeout(() => {
        const params = {
          ...this.bounds,
        };
        this.loadAvailableMarkers(params)
          .then(() => {
            this.busy = false;
            this.loadAvailableMarkersTimeout = null;
          })
          .catch(() => {
            this.busy = false;
          });
      }, 1000);
    },
  },
};
</script>
