<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 29 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6512 22.7258C28.3432 23.434 28.3432 24.5671 27.6512 25.2753L5.95176 47.4812C5.25972 48.1894 4.13707 48.1894 3.44502 47.4812L0.523058 44.5068C-0.168987 43.7986 -0.168987 42.6654 0.523058 41.9572L18.0241 24.0005L0.523058 6.04378C-0.168987 5.33558 -0.168987 4.20247 0.523058 3.49427L3.44502 0.519841C4.13707 -0.188356 5.25972 -0.188356 5.95176 0.519841L27.6512 22.7258Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AChevronRightIcon",
};
</script>

<style lang="scss" scoped></style>
