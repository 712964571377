<template>
  <BCard no-body class="shadow">
    <BCardHeader class="border-bottom-0 bg-info py-0" style="height: 20px" />
    <BCardBody class="pt-3 pb-0 px-lg-4">
      <h4 class="text-center mb-3">
        {{ $t("I am planning a distribution in") }}:
      </h4>
      <small class="d-block mb-3 text-muted text-center">
        {{ $t("Please enter a list of locations in the field below.") }}
        {{ $t("Please enter each location on a new line.") }}
        {{ $t("vMap calculates the circulation figures for you.") }}
      </small>

      <div class="w-100">
        <BFormTextarea
          v-model="form.addresses"
          :state="isValidAddress('addresses')"
          :placeholder="addressPlaceholder"
          style="min-height: 150px; max-height: calc(100vh - 470px)"
          @keydown="hasInvalidAddress = false"
        />
        <BFormInvalidFeedback
          v-if="!hasInvalidAddress"
          :state="isValid('addresses')"
        >
          {{ invalidFeedback("addresses") }}
        </BFormInvalidFeedback>
        <BFormInvalidFeedback v-else :state="false">
          {{ $t("validation.invalidSomeAddresses") }}
        </BFormInvalidFeedback>
      </div>
    </BCardBody>

    <BCardFooter class="text-center bg-white border-top-0 py-3">
      <BButton
        variant="primary-dark"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickCancel"
      >
        {{ $t("Cancel") }}
      </BButton>
      <BButton
        variant="primary"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickOk"
      >
        {{ $t("OK") }}
      </BButton>
    </BCardFooter>
  </BCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { maxLines } from "@/utils/validators";
import { createNamespacedHelpers } from "vuex";
import _ from "lodash";

import { SEARCH_DISTRIBUTION_AREAS_BY_POSTCODES } from "@/store/action-types";
import formMixin from "@/mixins/areaLocatorForm";

const { mapGetters, mapActions } = createNamespacedHelpers("distributionAreas");
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "MAreaLocatorPostcode",
  mixins: [validationMixin, formMixin],
  validations: {
    form: {
      addresses: { required, maxLines: maxLines(50) },
    },
  },
  inject: ["mapFitBounds"],
  data() {
    return {
      form: {
        addresses: "",
      },
    };
  },
  computed: {
    ...mapGetters(["currentGeomCompositionIds"]),
    ...mapConfigGetters(["addressPlaceholder"]),
  },
  methods: {
    ...mapActions({
      submit: SEARCH_DISTRIBUTION_AREAS_BY_POSTCODES,
    }),
    buildParams() {
      const lines = this.form.addresses
        .split("\n")
        .filter((address) => address && address.trim());
      return {
        addressArray: _.uniq(lines).map((line) => {
          return this.createAddressParam(line);
        }),
        excludeGeomCompositionsids: this.currentGeomCompositionIds,
      };
    },
    resetForm() {
      this.updateForm({ addresses: "" });
      this.hasInvalidAddress = false;
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/molecules/MAreaLocator.scss";
</style>
