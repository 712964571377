<template>
  <div
    v-if="label"
    class="d-inline-block text-center"
    :style="`width: calc(${size} + 20px)`"
  >
    <BButton
      class="p-2"
      :variant="variant"
      :class="localButtonClass"
      :style="buttonStyle"
      v-on="$listeners"
    >
      <component :is="`i-${icon}`" />
    </BButton>
    <small
      class="d-block text-uppercase text-body py-1"
      style="line-height: 1.1"
    >
      <small>{{ label }}</small>
    </small>
  </div>
  <BButton
    v-else
    class="p-2"
    :variant="variant"
    :class="localButtonClass"
    :style="buttonStyle"
  >
    <component :is="`i-${icon}`" />
  </BButton>
</template>

<script>
import Vue from "vue";

import APageRowsStampIcon from "../APageRowsStampIcon/APageRowsStampIcon.vue";
import APiePlusIcon from "../APiePlusIcon/APiePlusIcon.vue";
import ARowsSearchIcon from "../ARowsSearchIcon/ARowsSearchIcon.vue";
import ASearchIcon from "../ASearchIcon/ASearchIcon.vue";

Vue.component("i-page-rows-stamp", APageRowsStampIcon);
Vue.component("i-pie-plus", APiePlusIcon);
Vue.component("i-rows-search", ARowsSearchIcon);
Vue.component("i-search", ASearchIcon);

export default {
  name: "AButtonIcon",
  props: {
    size: {
      type: String,
      default: "2.5rem",
    },
    variant: {
      type: String,
      default: "secondary",
    },
    textVariant: {
      type: String,
      default: "",
    },
    icon: {
      type: [String, Object],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    buttonClass: {
      type: [String, Object],
      default: "",
    },
  },
  computed: {
    localButtonClass() {
      if (typeof this.buttonClass === "string") {
        return {
          [`text-${this.textVariant}`]: this.textVariant,
          [this.buttonClass]: true,
        };
      }
      return {
        [`text-${this.textVariant}`]: this.textVariant,
        ...this.buttonClass,
      };
    },
    buttonStyle() {
      return {
        width: this.size,
        height: this.size,
        lineHeight: 1,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
