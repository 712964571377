<template>
  <BRow class="align-items-end">
    <BCol v-for="item in newspapers" :key="item.id">
      <div class="d-flex flex-column justify-content-start">
        <div class="mb-2">
          <img :src="item.logo" class="img-fluid m-newspaper-logo" />
        </div>
        <BFormCheckboxGroup
          v-model="checked"
          class="m-newspaper-select"
          :options="item.options"
          :disabled="readonly"
          stacked
        />
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("configuration");

export default {
  name: "MNewspaperSelect",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["products"]),
    checked: {
      set(newspapers) {
        const selected =
          newspapers.length < 1 || newspapers.length > 3
            ? [...this.selected]
            : newspapers;
        setTimeout(() => {
          this.$emit("update:selected", selected);
        }, 100);
      },
      get() {
        return this.selected;
      },
    },
    newspapers() {
      return this.products.map((item, index) => {
        return {
          id: index,
          name: item.name,
          logo: item.logo,
          options: item.volumes.map(({ name: text, key: value }) => {
            return {
              text,
              value,
            };
          }),
          // options: Object.keys(item.options).map((key) => {
          //   return {
          //     text: item.options[key].name,
          //     value: key,
          //   };
          // }),
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/assets.scss";

.m-newspaper-select {
  .custom-control-label span {
    white-space: nowrap;
  }
}
// .m-newspaper-logo {
//   @include media-breakpoint-up(desktop) {
//     max-width: 200px;
//   }
// }
</style>
