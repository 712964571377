<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.728 48.312L18 47.832C7.392 44.928 0 35.28 0 24.36C0 17.904 2.568 11.784 7.224 7.176C11.856 2.544 18.024 0 24.528 0C34.584 0 43.776 6.264 47.4 15.576L48.048 17.256L26.016 25.704L19.728 48.312ZM24.528 3.576C12.984 3.576 3.6 12.912 3.6 24.36C3.6 33.072 9.12 40.848 17.232 43.848L23.016 23.016L43.296 15.24C39.84 8.208 32.496 3.576 24.528 3.576Z"
      fill="currentColor"
    />
    <path
      d="M46.488 37.224H24.96C24.096 37.224 23.4 36.528 23.4 35.664C23.4 34.8 24.096 34.104 24.96 34.104H46.488C47.352 34.104 48.048 34.8 48.048 35.664C48.048 36.528 47.352 37.224 46.488 37.224Z"
      fill="currentColor"
    />
    <path
      d="M35.712 48C34.848 48 34.152 47.304 34.152 46.44V24.912C34.152 24.048 34.848 23.352 35.712 23.352C36.576 23.352 37.272 24.048 37.272 24.912V46.44C37.272 47.304 36.576 48 35.712 48Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "APiePlusIcon",
};
</script>

<style lang="scss" scoped></style>
