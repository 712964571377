<template>
  <div v-if="!embeded">
    <footer class="o-footer bg-dark text-white fixed-bottom pt-3 py-md-3">
      <BNavbar toggleable="lg" type="dark" variant="transparent" class="py-0">
        <BNavbarBrand to="/">
          <div class="d-inline-block" style="width: 115px;">
            <img :src="logo" class="img-fluid" />
          </div>
        </BNavbarBrand>
        <BNavbarToggle target="navbarCollapse" class="border-0"></BNavbarToggle>
        <BCollapse id="navbarCollapse" is-nav>
          <BNavbarNav class="ml-auto">
            <BNavItem
              v-for="(link, index) in links"
              :key="index"
              :href="link.url"
              class="h5 mb-0"
              target="_blank"
            >
              {{ link.title }}
            </BNavItem>
          </BNavbarNav>
        </BCollapse>
      </BNavbar>
      <div class="o-footer-copyright text-light">
        <small>
          <small>
            &copy; Haiberg {{ currentYear }}. {{ $t("All rights reserved.") }}
          </small>
        </small>
      </div>
    </footer>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";

const { mapGetters } = createNamespacedHelpers("configuration");

export default {
  name: "OFooter",
  computed: {
    ...mapGetters({
      links: "footerLinks",
      logo: "footerLogo",
      embeded: "embeded",
    }),
    currentYear() {
      return moment().format("YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/assets.scss";
.bg-dark {
  background-color: rgba($dark, 0.85) !important;
}
.o-footer {
  z-index: 1040;
}
.o-footer-copyright {
  text-align: center;
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
