<template>
  <ul class="steps-progress list-inline d-flex justify-content-center mb-0">
    <li
      v-for="step in steps"
      :key="step"
      class="steps-progress-item list-inline-item d-inline-flex flex-columns align-items-center"
    >
      <span :class="itemClass(step)">
        {{ step }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MStepProgress",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    itemClass(step) {
      const isActive = step <= this.currentStep;
      return {
        "steps-progress-item-step": true,
        "rounded-circle text-center": true,
        "bg-light": !isActive,
        "text-muted": !isActive,
        "bg-success": isActive,
        "text-white": isActive,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/molecules/MStepProgress.scss";
</style>
