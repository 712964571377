var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length < 1)?_c('div',[_c('div',{staticClass:"text-center mb-4"},[_c('BButton',{staticClass:"btn-mw-150",attrs:{"variant":"light","pill":""}},[_vm._v("0")])],1),_c('h5',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("Your distribution areas")))])]):_c('div',{staticClass:"ml-n3 mr-n3"},[_c('div',{staticClass:"px-3"},[_c('MNewspaperSelect',{staticClass:"mb-4",attrs:{"selected":_vm.newspapers},on:{"update:selected":function($event){_vm.newspapers=$event}}}),_c('h5',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("Your distribution areas")))])],1),_c('BTable',{staticClass:"distributaion-area-table mb-0",attrs:{"items":_vm.sortedItems,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sticky-header":"","no-local-sorting":"","small":"","responsive":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"sort-changed":_vm.sortItems},scopedSlots:_vm._u([{key:"cell()",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{class:_vm.itemVariantClass(item)},[_vm._v(_vm._s(value))])]}},{key:"cell(marker)",fn:function(ref){
var item = ref.item;
return [(_vm.hasVolume(item))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.handleClickMarker(item)}}},[_c('img',{attrs:{"src":require('@/assets/images/position_marker_blue.png')}})]):_c('img',{attrs:{"src":require('@/assets/images/position_marker_red.png')}})]}},{key:"cell(plz)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(!item.hasSuggestions)?_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(value)+" ")]):_c('MDistributionAreaSuggestion',{attrs:{"text":value,"item":item}})]}},{key:"cell(ort)",fn:function(ref){
var value = ref.value;
return [_c('span',{staticClass:"d-inline-block",staticStyle:{"max-width":"240px"}},[_vm._v(_vm._s(value))])]}},{key:"cell(volume1)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{class:_vm.itemVariantClass(item)},[_vm._v(" "+_vm._s(value ? _vm.$n(value) : "-")+" ")])]}},{key:"cell(volume2)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{class:_vm.itemVariantClass(item)},[_vm._v(" "+_vm._s(value ? _vm.$n(value) : "-")+" ")])]}},{key:"cell(volume3)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{class:_vm.itemVariantClass(item)},[_vm._v(" "+_vm._s(value ? _vm.$n(value) : "-")+" ")])]}},{key:"cell(volume4)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{class:_vm.itemVariantClass(item)},[_vm._v(" "+_vm._s(value ? _vm.$n(value) : "-")+" ")])]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('BButton',{staticClass:"py-0 px-1",staticStyle:{"line-height":"0","margin-top":"-2px"},attrs:{"variant":"link","size":"sm"},on:{"click":function($event){return _vm.handleClickDelete(item)}}},[_c('img',{attrs:{"src":require('@/assets/images/times_dark.png')}})])]}},{key:"custom-foot",fn:function(){return _vm._l((_vm.totalRows.slice(0, 1)),function(row,index){return _c('ARowTotal',{key:index,attrs:{"row":row}})})},proxy:true}])}),(_vm.comments.length)?_c('ul',{staticClass:"position-absolute list-unstyled mb-0 px-3",staticStyle:{"bottom":"80px"}},_vm._l((_vm.comments),function(comment,index){return _c('li',{key:index,staticClass:"text-danger"},[_c('small',[_vm._v(_vm._s(comment.marker)+_vm._s(comment.message))])])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }