<template>
  <BCard no-body class="shadow">
    <BCardHeader class="border-bottom-0 bg-info py-0" style="height: 20px" />
    <BCardBody class="pt-3 pb-0 px-lg-4">
      <h4 class="text-center mb-3">
        {{ $t("I am planning a distribution in the radius of") }}
      </h4>
      <BFormRow class="mb-3">
        <BCol cols="12" lg="8" class="mb-3 mb-lg-0">
          <BFormSelect v-model="form.newspaper" :options="productOptions" />
        </BCol>
        <BCol cols="12" lg="4">
          <BInputGroup append="km">
            <BFormInput
              v-model="form.distance"
              :state="isValid('distance')"
              placeholder="10"
              @blur="validate('distance')"
            />
          </BInputGroup>
          <BFormInvalidFeedback :state="isValid('distance')">
            {{ invalidFeedback("distance") }}
          </BFormInvalidFeedback>
        </BCol>
      </BFormRow>

      <h4 class="text-center mb-3">
        {{ $t("around the following location") }}:
      </h4>
      <BFormInput
        v-model="form.address"
        :state="isValidAddress('address')"
        :placeholder="addressPlaceholder"
        @keydown="hasInvalidAddress = false"
      />

      <BFormInvalidFeedback
        v-if="!hasInvalidAddress"
        :state="isValid('address')"
      >
        {{ invalidFeedback("address") }}
      </BFormInvalidFeedback>
      <BFormInvalidFeedback v-else :state="false">
        {{ $t("validation.invalidAddress") }}
      </BFormInvalidFeedback>
    </BCardBody>

    <BCardFooter class="text-center bg-white border-top-0 py-3">
      <BButton
        variant="primary-dark"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickCancel"
      >
        {{ $t("Cancel") }}
      </BButton>
      <BButton
        variant="primary"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickOk"
      >
        {{ $t("OK") }}
      </BButton>
    </BCardFooter>
  </BCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  integer,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

import { SEARCH_DISTRIBUTION_AREAS_BY_RADIUS } from "@/store/action-types";
import formMixin from "@/mixins/areaLocatorForm";

const { mapActions } = createNamespacedHelpers("distributionAreas");
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

const { mapFields } = createHelpers({
  getterType: "distributionAreas/getField",
  mutationType: "distributionAreas/updateField",
});

export default {
  name: "MAreaLocatorRadius",
  mixins: [validationMixin, formMixin],
  validations: {
    form: {
      distance: {
        required,
        integer,
        minValue: minValue(1),
        maxValue: maxValue(1000),
      },
      address: { required },
    },
  },
  inject: ["mapFitBounds"],
  data() {
    return {
      form: {
        address: "",
        distance: "10",
        newspaper: "volume1",
      },
    };
  },
  computed: {
    ...mapConfigGetters([
      "productOptions",
      "addressPlaceholder",
      "searchDefaults",
    ]),
    ...mapFields(["newspapers"]),
    defaultRadius() {
      return parseInt(
        this.searchDefaults.radiusDistance
          ? this.searchDefaults.radiusDistance / 1000
          : 10
      );
    },
  },
  watch: {
    searchDefaults() {
      this.resetForm();
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...mapActions({
      submit: SEARCH_DISTRIBUTION_AREAS_BY_RADIUS,
    }),
    buildParams() {
      this.newspapers = [this.form.newspaper];
      return {
        address: this.createAddressParam(this.form.address),
        distance: parseInt(this.form.distance * 1000),
        volume: this.form.newspaper,
      };
    },
    resetForm() {
      this.updateForm({
        address: "",
        distance: this.defaultRadius,
        newspaper: "volume1",
      });
      this.hasInvalidAddress = false;
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/molecules/MAreaLocator.scss";
</style>
