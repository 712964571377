<template>
  <div v-if="items.length < 1">
    <div class="text-center mb-4">
      <BButton variant="light" pill class="btn-mw-150">0</BButton>
    </div>
    <h5 class="text-uppercase">{{ $t("Your distribution areas") }}</h5>
  </div>
  <div v-else class="ml-n3 mr-n3">
    <div class="px-3">
      <MNewspaperSelect :selected.sync="newspapers" class="mb-4" />
      <h5 class="text-uppercase">{{ $t("Your distribution areas") }}</h5>
    </div>
    <BTable
      :items="sortedItems"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sticky-header
      class="distributaion-area-table mb-0"
      no-local-sorting
      small
      responsive
      @sort-changed="sortItems"
    >
      <template v-slot:cell()="{ item, value }">
        <span :class="itemVariantClass(item)">{{ value }}</span>
      </template>
      <template v-slot:cell(marker)="{ item }">
        <a v-if="hasVolume(item)" href="#" @click="handleClickMarker(item)">
          <img :src="require('@/assets/images/position_marker_blue.png')" />
        </a>
        <img v-else :src="require('@/assets/images/position_marker_red.png')" />
      </template>
      <template v-slot:cell(plz)="{ item, value }">
        <span v-if="!item.hasSuggestions" class="text-primary">
          {{ value }}
        </span>
        <MDistributionAreaSuggestion v-else :text="value" :item="item" />
      </template>
      <template v-slot:cell(ort)="{ value }">
        <span class="d-inline-block" style="max-width: 240px">{{ value }}</span>
      </template>
      <template v-slot:cell(volume1)="{ item, value }">
        <span :class="itemVariantClass(item)">
          {{ value ? $n(value) : "-" }}
        </span>
      </template>
      <template v-slot:cell(volume2)="{ item, value }">
        <span :class="itemVariantClass(item)">
          {{ value ? $n(value) : "-" }}
        </span>
      </template>
      <template v-slot:cell(volume3)="{ item, value }">
        <span :class="itemVariantClass(item)">
          {{ value ? $n(value) : "-" }}
        </span>
      </template>
      <template v-slot:cell(volume4)="{ item, value }">
        <span :class="itemVariantClass(item)">
          {{ value ? $n(value) : "-" }}
        </span>
      </template>
      <template v-slot:cell(action)="{ item }">
        <BButton
          variant="link"
          size="sm"
          class="py-0 px-1"
          style="line-height: 0;margin-top: -2px;"
          @click="handleClickDelete(item)"
        >
          <img :src="require('@/assets/images/times_dark.png')" />
        </BButton>
      </template>
      <template v-slot:custom-foot>
        <ARowTotal
          v-for="(row, index) in totalRows.slice(0, 1)"
          :key="index"
          :row="row"
        />
      </template>
    </BTable>
    <ul
      v-if="comments.length"
      class="position-absolute list-unstyled mb-0 px-3"
      style="bottom: 80px"
    >
      <li v-for="(comment, index) in comments" :key="index" class="text-danger">
        <small>{{ comment.marker }}{{ comment.message }}</small>
      </li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";
import _ from "lodash";

import { TOGGLE_ITEM } from "@/store/mutation-types";

import { ARowTotal } from "@atoms";
import MNewspaperSelect from "@molecules/MNewspaperSelect/MNewspaperSelect.vue";
import MDistributionAreaSuggestion from "@molecules/MDistributionAreaSuggestion/MDistributionAreaSuggestion.vue";
import totalRows from "@/mixins/totalRows";

const { mapFields } = createHelpers({
  getterType: "distributionAreas/getField",
  mutationType: "distributionAreas/updateField",
});

const { mapMutations } = createNamespacedHelpers("distributionAreas");
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "MMediaPlanningDistributionArea",
  components: { MNewspaperSelect, MDistributionAreaSuggestion, ARowTotal },
  mixins: [totalRows],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      localItems: this.items,
    };
  },
  computed: {
    ...mapFields(["newspapers"]),
    ...mapConfigGetters(["productHeaders", "productComments", "products"]),
    comments() {
      return this.newspapers.reduce((comments, volume) => {
        if (this.productComments[volume]) {
          comments.push(this.productComments[volume]);
        }
        return comments;
      }, []);
    },
    sortedItems: {
      set(items) {
        this.localItems = items;
      },
      get() {
        const items = this.localItems.filter((item) => this.hasVolume(item));
        const itemsWithSuggestion = this.localItems.filter(
          (item) => !this.hasVolume(item)
        );
        return [...items, ...itemsWithSuggestion];
      },
    },
    newpapersFields() {
      return this.newspapers.reduce((fields, volume) => {
        const comment = this.productComments[volume] || null;
        const label = this.productHeaders[volume];
        fields.push({
          key: volume,
          labelHtml: comment
            ? `${label} <span class="text-danger">${comment.marker}</span>`
            : label,
          thClass: "text-right font-weight-normal text-nowrap",
          tdClass: "text-right",
        });
        return fields;
      }, []);
    },
    fields() {
      return [
        {
          key: "marker",
          label: "",
          thStyle: "width: 30px",
        },
        {
          key: "plz",
          label: this.$t("Post Code"),
          thStyle: "width: 60px",
          thClass: "font-weight-normal",
          sortable: true,
        },
        {
          key: "mainCity",
          label: this.$t("Location"),
          thClass: "font-weight-normal",
          sortable: true,
        },
        ...this.newpapersFields,
        {
          key: "action",
          label: "",
          thStyle: "width: 30px",
        },
      ];
    },
    totals() {
      const totals = [];
      this.newspapers.forEach((delivery) => {
        totals.push(
          this.items.reduce((total, row) => {
            return total + (row[delivery] || 0);
          }, 0)
        );
      });
      return totals;
    },
    stickyHeader() {
      const product = _.maxBy(
        this.products,
        (item) => Object.keys(item.options).length
      );
      const maxSize = Object.keys(product.options).length;
      const offset = 470 + 24 * maxSize;
      return `calc(100vh - ${offset}px)`;
    },
  },
  watch: {
    items(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        this.sortBy = "";
        this.sortDesc = false;
      } else {
        this.sortItems({ sortBy: this.sortBy, sortDesc: this.sortDesc });
      }
      this.localItems = newValue;
    },
  },
  methods: {
    ...mapMutations({ toggleItem: TOGGLE_ITEM }),
    sortItems({ sortBy, sortDesc }) {
      const items = _.sortBy(this.items, [sortBy]);
      this.sortedItems = sortDesc ? _.reverse(items) : items;
    },
    handleClickDelete(item) {
      this.toggleItem(item);
    },
    handleClickMarker(item) {
      this.$root.$emit("map-center", [item.lat, item.lon]);
    },
    processStep() {
      return new Promise((resolve) => {
        if (this.items.length) resolve();
      });
    },
    itemVariantClass(item, baseClass = "text-body") {
      return this.hasVolume(item) ? baseClass : "text-danger";
    },
    hasVolume(item) {
      return this.newspapers.reduce((isAvailable, volume) => {
        return !!(isAvailable || item[volume]);
      }, false);
    },
    applySuggestion(newItem, oldItem) {
      this.toggleItem(newItem);
      this.toggleItem(oldItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.distributaion-area-table.b-table-sticky-header {
  max-height: calc(100vh - 460px);
  @media (min-height: 740px) {
    max-height: calc(100vh - 480px);
  }
  @media (min-height: 900px) {
    max-height: calc(100vh - 490px);
  }

  @media (min-width: 1300px) {
    max-height: calc(100vh - 480px);
  }
  @media (min-height: 740px) and (min-width: 1300px) {
    max-height: calc(100vh - 490px);
  }
  @media (min-height: 900px) and (min-width: 1300px) {
    max-height: calc(100vh - 500px);
  }
}
</style>
