<template>
  <form @submit.prevent>
    <p class="mb-2">
      {{ $t("steps.registration.description") }}
    </p>
    <BFormRow class="mb-2">
      <BCol>
        <BFormInput
          v-model="firstName"
          :placeholder="`${$t('First Name')} *`"
          :state="isValid('firstName')"
          @blur="validate('firstName')"
        />
        <BFormInvalidFeedback :state="isValid('firstName')">
          {{ invalidFeedback("firstName") }}
        </BFormInvalidFeedback>
      </BCol>
      <BCol>
        <BFormInput
          v-model="lastName"
          :placeholder="`${$t('Last Name')} *`"
          :state="isValid('lastName')"
          @blur="validate('lastName')"
        />
        <BFormInvalidFeedback :state="isValid('lastName')">
          {{ invalidFeedback("lastName") }}
        </BFormInvalidFeedback>
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormInput
          v-model="email"
          :placeholder="`${$t('Email')} *`"
          :state="isValid('email')"
          @blur="validate('email')"
        />
        <BFormInvalidFeedback :state="isValid('email')">
          {{ invalidFeedback("email") }}
        </BFormInvalidFeedback>
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormInput v-model="phone" :placeholder="$t('Telephone')" />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormInput v-model="company" :placeholder="$t('Company')" />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormInput v-model="street" :placeholder="$t('Street')" />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol cols="5">
        <BFormInput v-model="postCode" :placeholder="$t('Post Code')" />
      </BCol>
      <BCol cols="7">
        <BFormInput v-model="location" :placeholder="$t('Location')" />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormInput
          v-model="clientNumber"
          :placeholder="$t('Client Number')"
          maxlength="8"
        />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <MFormDatepicker
          v-model="requestedDate"
          :placeholder="$t('Your Date')"
          :locale="$i18n.locale"
          hide-header
          label-help=""
          start-weekday="1"
          class="flex-row-reverse pl-2"
        />
        <i class="fas fa-redo" />
      </BCol>
    </BFormRow>

    <BFormRow class="mb-2">
      <BCol>
        <BFormTextarea
          v-model="comment"
          :placeholder="$t('form.registration.comment.default')"
          rows="3"
          style="min-height: 90px"
        />
      </BCol>
    </BFormRow>

    <BFormRow>
      <BCol>
        <BFormCheckbox v-model="terms1" size="sm">
          <span class="d-block">
            {{ $t("form.registration.terms1.label") }}
          </span>
          <span
            class="d-block"
            v-html="
              $t('form.registration.terms2.label').replace(
                '{{href}}',
                termsLink
              )
            "
          />
        </BFormCheckbox>
        <!-- <BFormCheckbox v-model="terms2" size="sm">
          {{ $t("form.registration.terms2.label") }}
        </BFormCheckbox> -->
        <BFormInvalidFeedback :state="isValid('terms')">
          {{ $t("validation.terms") }}
        </BFormInvalidFeedback>
      </BCol>
    </BFormRow>
    <BFormRow class="mt-3">
      <BCol class="pl-4">
        <small>{{ $t("form.notification.fields_required") }}</small>
      </BCol>
    </BFormRow>
  </form>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email, sameAs } from "vuelidate/lib/validators";
import { createHelpers } from "vuex-map-fields";

import { MFormDatepicker } from "@molecules";
import formMixin from "@/mixins/formMixin";

import { SUBMIT } from "@/store/action-types";
import { RESET } from "@/store/mutation-types";

const { mapFields } = createHelpers({
  getterType: "registrationForm/getField",
  mutationType: "registrationForm/updateField",
});

const { mapGetters: mapDistributionAreasGetters } = createNamespacedHelpers(
  "distributionAreas"
);
const {
  mapGetters: mapRegistrationFormGetters,
  mapMutations: mapRegistrationFormMutations,
} = createNamespacedHelpers("registrationForm");
const { mapActions: mapPlanActions } = createNamespacedHelpers("plan");
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "MMediaPlanningRegistration",
  components: { MFormDatepicker },
  mixins: [validationMixin, formMixin],
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
    terms1: { sameAs: sameAs(() => true) },
    terms: ["terms1"],
  },
  computed: {
    ...mapRegistrationFormGetters({ registrationForm: "form" }),
    ...mapDistributionAreasGetters(["newspapers", "items"]),
    ...mapConfigGetters(["termsLink"]),
    ...mapFields([
      "firstName",
      "lastName",
      "email",
      "phone",
      "company",
      "street",
      "postCode",
      "location",
      "clientNumber",
      "requestedDate",
      "comment",
      "terms1",
      "terms2",
    ]),
  },
  methods: {
    ...mapPlanActions({ submit: SUBMIT }),
    ...mapRegistrationFormMutations({ resetRegistrationForm: RESET }),
    createData() {
      return {
        ...this.registrationForm,
        geomCompositionsids: this.items
          .map((item) => item.geomCompositionsid)
          .join(","),
        volumes: this.newspapers.join(","),
      };
    },
    processStep() {
      return new Promise((resolve) => {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.submit(this.createData()).then(() => {
            this.$nextTick(() => {
              this.resetRegistrationForm();
            });
            resolve();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
