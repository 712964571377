<template>
  <div>
    <OMap underlay />
    <OContent>
      Review
    </OContent>
    <OFooter />
  </div>
</template>

<script>
import { OContent, OFooter, OMap } from "@organisms";

export default {
  name: "TReview",
  components: { OContent, OFooter, OMap },
};
</script>

<style lang="scss" scoped></style>
