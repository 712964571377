export default {
  methods: {
    isFieldCheckable(field) {
      if (
        !this.$v ||
        (!this.$v[field] && (!this.$v.form || !this.$v.form[field]))
      ) {
        return false;
      }
      return true;
    },
    isValid(field) {
      if (!this.isFieldCheckable(field)) {
        return null;
      }
      const validation = this.$v.form ? this.$v.form[field] : this.$v[field];
      if (!validation || !validation.$dirty) {
        return null;
      }
      return !validation.$error;
    },
    invalidFeedback(field) {
      if (!this.isFieldCheckable(field)) {
        return "";
      }
      const validation = this.$v.form ? this.$v.form[field] : this.$v[field];
      const element = this.$refs[field];

      if (!validation || !validation.$dirty) {
        return "";
      }
      const validators = Object.keys(validation).filter((v) => {
        return !v.startsWith("$") && !validation[v];
      });
      let params = validation.$params[validators[0]];

      if (validation.$invalid && !params && !!element) {
        params = { type: validators[0] };
        if (validators[0].startsWith("max")) {
          params.max = element.max;
        } else if (validators[0].startsWith("min")) {
          params.min = element.min;
        }
      }

      return validators.length
        ? this.$t(`validation.${validators[0]}`, { ...params })
        : "";
    },
    validate(field) {
      if (!this.isFieldCheckable(field)) {
        return null;
      }
      if (this.$v.form && this.$v.form[field]) {
        this.$v.form[field].$touch();
      } else if (this.$v[field]) {
        this.$v[field].$touch();
      }
    },
    validateAll() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
};
