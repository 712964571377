<template>
  <BCard no-body class="shadow">
    <BCardHeader class="border-bottom-0 bg-info py-0" style="height: 20px" />
    <BCardBody class="pt-3 pb-0 px-lg-4">
      <h4 class="text-center mb-3">
        {{ $t("I plan a distribution with") }}
      </h4>
      <BFormRow>
        <BCol cols="12" lg="5" class="mb-3">
          <BInputGroup :append="$t('piece')">
            <BFormInput
              v-model="form.amount"
              :state="isValid('amount')"
              placeholder="5000"
              @blur="validate('amount')"
            />
          </BInputGroup>
          <BFormInvalidFeedback :state="isValid('amount')" class="text-nowrap">
            {{ invalidFeedback("amount") }}
          </BFormInvalidFeedback>
        </BCol>
        <BCol cols="12" lg="7" class="mb-3">
          <BFormSelect v-model="form.newspaper" :options="productOptions" />
        </BCol>
      </BFormRow>
      <!-- <div class="d-flex flex-column flex-lg-row align-items-start mb-3">
        <div class="d-flex mb-3 mb-lg-0">
          <div style="width: 110px">
            <BFormInput
              v-model="form.amount"
              :state="isValid('amount')"
              placeholder="5000"
              @blur="validate('amount')"
            />
            <BFormInvalidFeedback
              :state="isValid('amount')"
              class="text-nowrap"
            >
              {{ invalidFeedback("amount") }}
            </BFormInvalidFeedback>
          </div>
          <div class="px-2" style="line-height: 32px">{{ $t("piece") }}</div>
        </div>
        <div class="mb-3 w-100">
          <BFormSelect v-model="form.newspaper" :options="productOptions" />
        </div>
      </div> -->

      <h4 class="text-center mb-3">
        {{ $t("households around the following location") }}:
      </h4>
      <BFormInput
        v-model="form.address"
        :state="isValidAddress('address')"
        :placeholder="addressPlaceholder"
        @keydown="hasInvalidAddress = false"
      />
      <BFormInvalidFeedback
        v-if="!hasInvalidAddress"
        :state="isValid('address')"
      >
        {{ invalidFeedback("address") }}
      </BFormInvalidFeedback>
      <BFormInvalidFeedback v-else :state="false">
        {{ $t("validation.invalidAddress") }}
      </BFormInvalidFeedback>
    </BCardBody>

    <BCardFooter class="text-center bg-white border-top-0 py-3">
      <BButton
        variant="primary-dark"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickCancel"
      >
        {{ $t("Cancel") }}
      </BButton>
      <BButton
        variant="primary"
        pill
        class="btn-area-locator btn-mw-150 py-2 px-3"
        @click="handleClickOk"
      >
        {{ $t("OK") }}
      </BButton>
    </BCardFooter>
  </BCard>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, integer, minValue } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";

import { SEARCH_DISTRIBUTION_AREAS_BY_NUMBER_OF_COPIES } from "@/store/action-types";
import formMixin from "@/mixins/areaLocatorForm";

const { mapActions } = createNamespacedHelpers("distributionAreas");
const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

const { mapFields } = createHelpers({
  getterType: "distributionAreas/getField",
  mutationType: "distributionAreas/updateField",
});

export default {
  name: "MAreaLocatorCopies",
  mixins: [validationMixin, formMixin],
  validations: {
    form: {
      amount: { required, integer, minValue: minValue(1) },
      address: { required },
    },
  },
  inject: ["mapFitBounds"],
  data() {
    return {
      form: {
        address: "",
        amount: "",
        newspaper: "volume1",
      },
    };
  },
  computed: {
    ...mapConfigGetters([
      "productOptions",
      "addressPlaceholder",
      "searchDefaults",
    ]),
    ...mapFields(["newspapers"]),
  },
  watch: {
    searchDefaults() {
      this.resetForm();
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    ...mapActions({
      submit: SEARCH_DISTRIBUTION_AREAS_BY_NUMBER_OF_COPIES,
    }),
    buildParams() {
      this.newspapers = [this.form.newspaper];
      return {
        address: this.createAddressParam(this.form.address),
        amount: this.form.amount,
        volume: this.form.newspaper,
      };
    },
    resetForm() {
      this.updateForm({
        address: "",
        amount: this.searchDefaults.auflageAmount || "",
        newspaper: "volume1",
      });
      this.hasInvalidAddress = false;
      this.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/molecules/MAreaLocator.scss";
</style>
