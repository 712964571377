<template>
  <div :class="wrapperClass">
    <LMap
      ref="map"
      :zoom.sync="currentZoom"
      :center.sync="currentCenter"
      :options="mapOptions"
      :bounds.sync="currentBounds"
    >
      <LTileLayer :url="url" />
      <LGeoJson
        v-if="polygon"
        key="polygon"
        :geojson="polygon.geojson"
        :options="polygon.options"
        @ready="handlePolygonReady"
      />
      <LGeoJson
        v-if="markers"
        key="markers"
        :geojson="markers.geojson"
        :options="markers.options"
      />
      <!-- <LMarker
        v-for="marker in markers"
        :key="marker.id"
        :name="marker.name"
        :lat-lng="marker.latLng"
        :icon="marker.icon"
        @click="marker.handleClick"
      >
        <LTooltip>{{ marker.name }}</LTooltip>
      </LMarker> -->
      <LControlZoom v-if="zoomControl" position="topright" />
    </LMap>
  </div>
</template>

<script>
import L from "leaflet";
import "@/vendors/leaflet-canvas-markers";

export default {
  name: "OMap",
  inheritAttrs: false,
  props: {
    underlay: {
      type: Boolean,
      default: false,
    },
    markers: {
      type: Object,
      default: null,
    },
    canvasMarkers: {
      type: Array,
      default: null,
    },
    polygon: {
      type: Object,
      default: null,
    },
    zoom: {
      type: Number,
      default: 7,
    },
    zoomControl: {
      type: Boolean,
      default: false,
    },
    center: {
      type: [Array, Object],
      default: () => [49.37747443902155, 7.8062209021300095],
    },
    bounds: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      url: "https://osm.haiberg.net/{z}/{x}/{y}.png",
      currentCenter: this.center,
      currentZoom: this.zoom,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
        scrollWheelZoom: this.zoomControl,
        preferCanvas: true,
      },
      map: null,
      currentPolygonLayer: null,
      originalBounds: null,
      currentBounds: this.bounds,
      mapObject: null,
      canvasIconLayer: null,
    };
  },
  computed: {
    wrapperClass() {
      return {
        "map-wrapper": true,
        "map-wrapper__underlay": this.underlay,
      };
    },
  },
  watch: {
    currentBounds(newValue) {
      this.$emit("update:bounds", {
        northEast: JSON.stringify({
          lat: newValue.getNorthEast().lat,
          lon: newValue.getNorthEast().lng,
        }),
        southWest: JSON.stringify({
          lat: newValue.getSouthWest().lat,
          lon: newValue.getSouthWest().lng,
        }),
      });
    },
    canvasMarkers() {
      this.updateMarkers();
    },
  },
  mounted() {
    this.mapObject = this.$refs.map.mapObject;
    this.canvasIconLayer = L.canvasIconLayer({ pane: "markerPane" }).addTo(
      this.mapObject
    );
    this.canvasIconLayer.addOnClickListener((_event, items) => {
      if (items[0].data.handleClick) items[0].data.handleClick();
    });
    this.originalBounds = this.mapObject.getBounds();
    this.fitBounds();
  },
  methods: {
    updateMarkers() {
      this.canvasIconLayer.clearLayers();
      if (this.canvasMarkers && this.canvasMarkers.length > 0) {
        window.map = this.mapObject;
        window.iconLayer = this.canvasIconLayer;
        this.canvasIconLayer.addLayers(this.canvasMarkers);
      }
    },
    handlePolygonReady(polygonLayer) {
      this.currentPolygonLayer = polygonLayer;
    },
    fitBounds() {
      this.mapObject.fitBounds(
        this.currentPolygonLayer
          ? this.currentPolygonLayer.getBounds()
          : this.originalBounds
      );
      // setTimeout(() => {
      //   try {
      //     this.mapObject.fitBounds(this.currentPolygonLayer.getBounds());
      //   } catch {
      //     this.mapObject.fitBounds(this.originalBounds);
      //   }
      // }, 600);
    },
    updateCenter(center) {
      this.currentCenter = center;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-wrapper__underlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
</style>
