<template>
  <div class="o-content position-fixed d-flex align-items-center w-auto">
    <BCard class="o-content-card rounded-0 card-rounded-sm-right shadow">
      <template v-if="$slots.header" #header>
        <slot name="header" />
      </template>
      <slot />
      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </BCard>
  </div>
</template>

<script>
export default {
  name: "OContent",
};
</script>

<style lang="scss" scoped>
.o-content {
  top: 0;
  bottom: 0;
  #app:not(.embeded) & {
    bottom: var(--footer-height);
  }
}
</style>
