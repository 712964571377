<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 29 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.535084 22.7253C-0.173112 23.4335 -0.173112 24.5666 0.535084 25.2748L22.741 47.4807C23.4492 48.1889 24.598 48.1889 25.3062 47.4807L28.2964 44.5063C29.0046 43.7981 29.0046 42.6649 28.2964 41.9567L10.3869 24L28.2964 6.04328C29.0046 5.33509 29.0046 4.20197 28.2964 3.49377L25.3062 0.519345C24.598 -0.188852 23.4492 -0.188852 22.741 0.519345L0.535084 22.7253Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "AChevronLeftIcon",
};
</script>

<style lang="scss" scoped></style>
