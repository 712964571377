<template>
  <BFormDatepicker v-bind="localAttrs" v-on="$listeners">
    <template #nav-this-month>
      <span class="d-inline-block" style="width: 17.5px; height: 17.5px">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="redo"
          class="svg-inline--fa fa-redo fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
          ></path>
        </svg>
      </span>
    </template>
  </BFormDatepicker>
</template>

<script>
export default {
  name: "MFormDatepicker",
  computed: {
    localAttrs() {
      const defaults = {};
      return {
        ...defaults,
        ...this.$attrs,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/molecules/MFormDatepicker.scss";
</style>
