<template>
  <div>
    <MAreaLocatorControls
      v-model="currentTab"
      position="top-center"
      position-sm="right-center"
    />
    <MAreaLocatorTabs v-model="currentTab">
      <MAreaLocatorTab :title="$t('Postcode selection')">
        <MAreaLocatorPostcode />
      </MAreaLocatorTab>
      <MAreaLocatorTab :title="$t('Radius')">
        <MAreaLocatorRadius />
      </MAreaLocatorTab>
      <MAreaLocatorTab :title="$t('Number of copies')">
        <MAreaLocatorCopies />
      </MAreaLocatorTab>
    </MAreaLocatorTabs>
  </div>
</template>

<script>
import {
  MAreaLocatorControls,
  MAreaLocatorCopies,
  MAreaLocatorPostcode,
  MAreaLocatorRadius,
  MAreaLocatorTab,
  MAreaLocatorTabs,
} from "@molecules";

export default {
  name: "OAreaLocator",
  components: {
    MAreaLocatorControls,
    MAreaLocatorCopies,
    MAreaLocatorPostcode,
    MAreaLocatorRadius,
    MAreaLocatorTab,
    MAreaLocatorTabs,
  },
  data() {
    return {
      currentTab: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
