<template>
  <div v-bind="bindAttrs">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MAreaLocatorTabs",
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapGetters(["sidebarVisible"]),
    currentTab: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    bindAttrs() {
      const defaults = {
        class: "m-area-locator-tabs",
      };
      if (this.sidebarVisible) {
        return {
          class: "m-area-locator-tabs open",
        };
      }
      return defaults;
    },
  },
  watch: {
    currentTab(newValue) {
      this.setCurrentTab(newValue);
    },
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.setCurrentTab(this.currentTab);
  },
  methods: {
    setCurrentTab(tabIndex) {
      this.tabs.forEach((tab) => {
        tab.isActive = false;
      });
      if (this.tabs[tabIndex]) {
        this.tabs[tabIndex].isActive = true;
      }
      this.$emit("input", tabIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/assets.scss";

.m-area-locator-tabs {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: auto;
  transform: translateY(-50%);
  padding-right: 90px;
  padding-left: 20px;
  z-index: -1;
  &.open {
    padding-left: calc(#{$sidebar-width} - 0.6rem);
  }
  @include media-breakpoint-up(laptop) {
    &.open {
      padding-left: calc(#{$sidebar-laptop-width} - 0.6rem);
    }
  }
  @include media-breakpoint-up(desktop) {
    padding-right: 100px;
    &.open {
      padding-left: calc(#{$sidebar-desktop-width} - 0.6rem);
    }
  }
}

// .m-area-locator-tabs {
//   z-index: -1;
//   width: 100%;
//   @include media-breakpoint-up(sm) {
//     padding-left: 50px;
//     padding-right: 122px;
//   }
//   @include media-breakpoint-up(lg) {
//     width: auto;
//   }
//   @include media-breakpoint-up(xl) {
//     &.open {
//       padding-left: 596px;
//     }
//   }
// }

// .m-area-locator-tabs.open {
//   margin-right: 150px;
//   @include media-breakpoint-up(xl) {
//     margin-right: 22%;
//   }
// }
</style>
