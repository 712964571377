<template>
  <MMediaPlanningCard
    class="rounded-0 card-rounded-sm-right shadow"
    :class="{
      'm-media-planning-card-thank-you': isThankYou,
    }"
  >
    <template #header>
      <MStepProgress :steps="steps" :current-step="localCurrentStep" />
    </template>
    <FadeTransition group tag="div" class="py-0 px-3">
      <MMediaPlanningDistributionArea
        v-if="isDistributionArea"
        ref="currentStep"
        key="distribution-area"
        :items="selectedDistributionAreas"
      />
      <MMediaPlanningRegistration
        v-if="isRegistration"
        ref="currentStep"
        key="registration"
      />
      <MMediaPlanningOverview
        v-if="isOverview"
        ref="currentStep"
        key="overview"
        :items="selectedDistributionAreas"
      />
    </FadeTransition>
    <template #footer>
      <div v-if="isDistributionArea">
        <BButton
          variant="primary-dark"
          pill
          class="btn-mw-150 py-2 px-3 mr-2 mr-desktop-5"
          @click="handleClickNew"
        >
          {{ $t("New Planning") }}
        </BButton>
        <BButton
          variant="primary"
          pill
          class="btn-mw-150 py-2 px-3"
          @click="handleClickContinue"
        >
          {{ $t("Finalize Planning") }}
        </BButton>
      </div>
      <div v-if="isRegistration">
        <BButton
          variant="primary-dark"
          pill
          class="btn-mw-150 py-2 px-3 mr-5"
          @click="handleClickBack"
        >
          {{ $t("Back") }}
        </BButton>
        <BButton
          variant="primary"
          pill
          class="btn-mw-150 py-2 px-3"
          @click="handleClickContinue"
        >
          {{ $t("Send Request") }}
        </BButton>
      </div>
      <div v-if="isOverview">
        <BButton
          variant="primary"
          pill
          class="btn-mw-150 py-2 px-3"
          @click="handleClickNew"
        >
          {{ $t("New Planning") }}
        </BButton>
      </div>
    </template>
  </MMediaPlanningCard>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapFields } from "vuex-map-fields";

import welcomeCardImg from "@/assets/images/welcome-card.jpg";

import {
  MMediaPlanningCard,
  MMediaPlanningDistributionArea,
  MMediaPlanningOverview,
  MMediaPlanningRegistration,
  MStepProgress,
} from "@molecules";

export default {
  name: "OMediaPlanningSteps",
  components: {
    MMediaPlanningCard,
    MMediaPlanningDistributionArea,
    MMediaPlanningOverview,
    MMediaPlanningRegistration,
    MStepProgress,
    FadeTransition,
  },
  data() {
    return {
      steps: [1, 2, 3],
      currentStep: 1,
      welcomeCardImg,
    };
  },
  computed: {
    ...mapFields("distributionAreas", { selectedDistributionAreas: "items" }),
    localCurrentStep() {
      if (this.currentStep <= 3) {
        return this.currentStep;
      }
      return 3;
    },
    isDistributionArea() {
      return this.currentStep === 1;
    },
    isRegistration() {
      return this.currentStep === 2;
    },
    isOverview() {
      return this.currentStep === 3;
    },
    isThankYou() {
      return this.currentStep === 4;
    },
  },
  watch: {
    currentStep(newValue) {
      this.$emit("step-changed", newValue);
    },
  },
  methods: {
    handleClickNew() {
      this.selectedDistributionAreas = [];
      this.currentStep = 1;
    },
    handleClickContinue() {
      const { processStep } = this.$refs.currentStep || {};
      if (processStep) {
        processStep().then(() => {
          this.currentStep++;
        });
      } else {
        this.currentStep++;
      }
    },
    handleClickBack() {
      this.currentStep--;
    },
    handleClickBackToHomepage() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss"></style>
