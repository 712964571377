<template>
  <BTr v-if="row.totals && row.totals.length" :class="row.class || null">
    <BTd v-if="row.label" class="text-right text-nowrap" :colspan="row.colspan">
      {{ row.label }}
    </BTd>
    <BTd
      v-for="(total, index) in row.totals"
      :key="index"
      :colspan="total.colspan"
      class="text-right"
    >
      <span v-if="row.money">
        {{ $n(total.value, "currency") }}
      </span>
      <span v-else class="text-nowrap">
        {{ $n(total.value) }} {{ row.suffix }}
      </span>
    </BTd>
  </BTr>
</template>

<script>
export default {
  name: "ATotals",
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped></style>
