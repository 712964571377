import { sumBy } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("configuration");

export default {
  computed: {
    ...mapGetters(["productPrices"]),
    totalRows() {
      const total = {
        label: this.$t("Total"),
        totals: [],
        colspan: 3,
      };
      const pricePer1000 = {
        label: this.$t("Price Per 1000 Ex."),
        money: this.$t("money"),
        totals: [],
      };
      const numberOfCopies = {
        label: this.$t("Number of copies"),
        suffix: this.$t("piece"),
        class: this.newspapers.length > 1 ? null : "row-summary",
        totals: [],
      };
      const price = {
        label: this.$t("Price Excl. Tax"),
        money: this.$t("money"),
        totals: [],
      };
      const tax = {
        label: this.$t("19% Tax"),
        money: this.$t("money"),
        totals: [],
      };
      const priceWithTax = {
        label: this.$t("Price Incl. Tax"),
        money: this.$t("money"),
        class: this.newspapers.length > 1 ? null : "row-summary",
        totals: [],
      };
      this.newspapers.forEach((volume) => {
        const qty = this.items.reduce((total, row) => {
          return total + (row[volume] || 0);
        }, 0);
        total.totals.push({
          value: qty,
          colspan: null,
        });
        pricePer1000.totals.push({
          value: this.roundPrice(this.productPrice(volume)),
          colspan: null,
        });
        numberOfCopies.totals.push({
          value: qty,
          colspan: null,
        });
        price.totals.push({
          value: this.roundPrice((qty * this.productPrice(volume)) / 1000),
          colspan: null,
        });
        tax.totals.push({
          value: this.roundPrice(
            (qty * this.productPrice(volume, "tax")) / 1000
          ),
          colspan: null,
        });
        priceWithTax.totals.push({
          value:
            this.roundPrice((qty * this.productPrice(volume)) / 1000) +
            this.roundPrice((qty * this.productPrice(volume, "tax")) / 1000),
          colspan: null,
        });
      });
      const totalNumberOfCopies =
        this.newspapers.length > 1
          ? {
              label: this.$t("Total number of copies"),
              suffix: this.$t("piece"),
              class: "row-summary",
              totals: [
                {
                  value: sumBy(total.totals, "value"),
                  colspan: this.newspapers.length,
                },
              ],
            }
          : {};
      const grandTotal =
        this.newspapers.length > 1
          ? {
              label: this.$t("Grand Total Incl. Tax"),
              money: this.$t("money"),
              class: "row-summary",
              totals: [
                {
                  value: sumBy(priceWithTax.totals, "value"),
                  colspan: this.newspapers.length,
                },
              ],
            }
          : {};
      const showPrice = Object.keys(this.productPrices).length;
      return showPrice
        ? [
            total,
            pricePer1000,
            numberOfCopies,
            totalNumberOfCopies,
            price,
            tax,
            priceWithTax,
            grandTotal,
          ]
        : [total];
    },
  },
  methods: {
    productPrice(volume, priceField = "price") {
      return this.productPrices[volume]
        ? this.productPrices[volume][0][priceField]
        : 0;
    },
    roundPrice(num) {
      return parseFloat(num.toFixed(2));
    },
  },
};
