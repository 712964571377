<template>
  <div class="a-sidebar-toggler d-inline-block bg-white overflow-hidden shadow">
    <BButton
      v-if="open"
      key="left"
      variant="link"
      class="text-body px-2"
      style="height: 100px; width: 30px;"
      v-on="$listeners"
    >
      <AChevronLeftIcon />
    </BButton>
    <BButton
      v-if="!open"
      key="right"
      variant="link"
      class="text-body px-2"
      style="height: 100px; width: 30px;"
      v-on="$listeners"
    >
      <AChevronRightIcon />
    </BButton>
  </div>
</template>

<script>
import AChevronLeftIcon from "../AChevronLeftIcon/AChevronLeftIcon.vue";
import AChevronRightIcon from "../AChevronRightIcon/AChevronRightIcon.vue";

export default {
  name: "ASidebarToggler",
  components: { AChevronLeftIcon, AChevronRightIcon },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("update:open", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.a-sidebar-toggler {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}
</style>
