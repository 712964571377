<template>
  <BCard v-bind="cardAttrs">
    <AButtonIcon
      v-bind="bindButtonAttrs(0)"
      icon="rows-search"
      :label="$t('Postcode selection')"
      @click="$emit('input', 0 === value ? -1 : 0)"
    />
    <AButtonIcon
      v-bind="bindButtonAttrs(1)"
      icon="pie-plus"
      :label="$t('Radius')"
      @click="$emit('input', 1 === value ? -1 : 1)"
    />
    <AButtonIcon
      v-bind="bindButtonAttrs(2)"
      icon="page-rows-stamp"
      :label="$t('Number of copies')"
      @click="$emit('input', 2 === value ? -1 : 2)"
    />
  </BCard>
</template>

<script>
import { AButtonIcon } from "@atoms";

export default {
  name: "MAreaLocatorControls",
  components: { AButtonIcon },
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      verticalPositions: ["left", "left-center", "right", "right-center"],
      positions: {
        position: "",
        "position-sm": "-sm",
        "position-md": "-md",
        "position-lg": "-lg",
        "position-xl": "-xl",
      },
    };
  },
  computed: {
    cardClass() {
      const classes = { shadow: true };
      return Object.keys(this.positions).reduce((classes, key) => {
        const infix = this.positions[key];
        const position = this.$attrs[key];
        const rounded = this.rounded(position);
        classes[`position-absolute${infix}-${position}`] = !!position;
        classes[`card-rounded${infix}-${rounded}`] = !!rounded;
        return classes;
      }, classes);
    },
    cardBodyClass() {
      const classes = { "p-2 p-sm-3 d-flex align-items-start": true };
      return Object.keys(this.positions).reduce((classes, key) => {
        const infix = this.positions[key];
        const isVertical = this.isVertical(this.$attrs[key]);
        classes[
          `flex${infix}-column justify-contect${infix}-center`
        ] = isVertical;
        return classes;
      }, classes);
    },
    cardAttrs() {
      return {
        class: this.cardClass,
        bodyClass: this.cardBodyClass,
      };
    },
    buttonClass() {
      return Object.keys(this.positions).reduce((classes, key) => {
        const infix = this.positions[key];
        const isVertical = this.isVertical(this.$attrs[key]);
        classes[`mb${infix}-1`] = isVertical;
        return classes;
      }, {});
    },
  },
  methods: {
    bindButtonAttrs(tab) {
      const attrs = {
        variant: tab === this.value ? "success" : "secondary",
        textVariant: "white",
        size: "40px",
        class: "m-area-locator-controls-button",
      };
      if (tab !== 2) {
        return {
          ...attrs,
          class: {
            ...this.buttonClass,
            "m-area-locator-controls-button": true,
          },
        };
      }
      return attrs;
    },
    isVertical(position) {
      return this.verticalPositions.indexOf(position) !== -1;
    },
    rounded(position) {
      const rounded = {
        left: "right",
        "left-center": "right",
        right: "left",
        "right-center": "left",
        top: "bottom",
        "top-center": "bottom",
        bottom: "top",
        "bottom-center": "top",
      };
      return rounded[position] || "";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/assets.scss";

.m-area-locator-controls-button button {
  @include media-breakpoint-up(sm) {
    width: 60px !important;
    height: 60px !important;
  }
}
</style>
