<template>
  <div>
    <div class="p-3 mb-3">
      <h6>{{ $t("steps.overview.title") }}</h6>
      <p class="mb-0" style="max-width: 600px">
        {{ $t("steps.overview.description") }}
      </p>
    </div>
    <BTable
      :items="[]"
      :fields="fields"
      sticky-header="calc(100vh - 440px)"
      small
      responsive
      borderless
      table-class="table-totals"
    >
      <template v-slot:custom-foot>
        <ARowTotal
          v-for="(row, index) in totalRows.slice(1)"
          :key="index"
          :row="row"
        />
      </template>
    </BTable>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import { ARowTotal } from "@atoms";
import totalRows from "@/mixins/totalRows";

const { mapGetters: mapDistributionAreasGetters } = createNamespacedHelpers(
  "distributionAreas"
);
const { mapGetters: mapConfigurationGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "MMediaPlanningOverview",
  components: { ARowTotal },
  mixins: [totalRows],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapDistributionAreasGetters(["newspapers"]),
    ...mapConfigurationGetters(["productHeaders"]),
    newpapersFields() {
      return this.newspapers.reduce((fields, delivery) => {
        fields.push({
          key: delivery,
          label: this.productHeaders[delivery],
          thClass: "text-right font-weight-normal",
          tdClass: "text-right",
        });
        return fields;
      }, []);
    },
    fields() {
      return [
        {
          key: "marker",
          label: "",
          thStyle: "width: 30px",
        },
        ...this.newpapersFields,
      ];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/assets.scss";

.table-totals {
  thead tr th {
    border-top: 1px solid $gray-900;
    border-bottom: 1px solid $gray-900;
  }
  .row-summary td {
    font-weight: bold;
    border-bottom: 1px solid $gray-900;
  }
}
</style>
