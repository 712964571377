import formMixin from "@/mixins/formMixin";

export default {
  mixins: [formMixin],
  data() {
    return {
      hasInvalidAddress: false,
    };
  },
  methods: {
    handleClickOk() {
      this.hasInvalidAddress = false;
      if (!this.validateAll()) {
        return;
      }
      this.$root.$emit("loading", true);
      this.submit(this.buildParams())
        .then(this.handleSuccess)
        .catch(this.handleError);
    },
    handleClickCancel() {
      this.resetForm();
      this.$parent.$parent.setCurrentTab(-1);
    },
    handleSuccess() {
      this.mapFitBounds();
      this.handleClickCancel();
      this.$root.$emit("loading", false);
    },
    handleError(error) {
      this.$v.$reset();
      this.hasInvalidAddress = true;
      this.$root.$emit("loading", false);
      if (error.data) {
        this.updateForm(error.data);
      }
    },
    isValidAddress(field) {
      const validationState = this.isValid(field);
      if (validationState === null) {
        return this.hasInvalidAddress === false ? null : false;
      }
      return validationState;
    },
    updateForm(data) {
      this.form = {
        ...this.form,
        ...data,
      };
    },
    createAddressParam(address) {
      if (
        address.indexOf(" ") === -1 &&
        !(typeof address === "number" && isFinite(address))
      ) {
        address += " ";
      }
      const plz = address.split(" ")[0];
      const ort = address
        .split(" ")
        .slice(1)
        .join(" ");
      const exp = new RegExp("^\\d+$");
      return exp.test(plz)
        ? { plz, ort }
        : { plz: "", ort: [plz, ort].join(" ").trim() };
    },
    buildParams() {
      throw new Error("The 'buildParams' method must to be declared.");
    },
    submit() {
      throw new Error("The 'submit' method must to be declared.");
    },
    resetForm() {
      throw new Error("The 'resetForm' method must to be declared.");
    },
  },
};
