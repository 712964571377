<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 157 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.37053H11.3776L17.2103 26.7039L22.7708 9.37053L34.0753 9.4184L23.0416 40.0248L11.6423 40.0373L0 9.37053ZM35.7083 0.0371952H51.1133L58.9616 28.6959L67.7901 0L82.375 0.0371914V40.0372H71.7083L71.4792 15.3705L64.4253 40.0372H53.499L45.7658 13.3705L46.143 40.1921L35.7083 40.0372V0.0371952ZM83.8632 39.9719L97.368 0.0371952L111.4 0.077446L125.392 39.8823L112.035 39.9598L113.169 24.1873C113.169 24.1873 108.287 24.2522 102.645 27.9339C97.4194 31.3445 92.4576 39.9249 92.4576 39.9249L83.8632 39.9719ZM127.631 0.0371952C146.401 0.0149752 156.776 -0.0382662 156.809 13.2528C156.778 22.6342 152.065 25.697 138.452 25.9294L138.375 40.0372H127.708L127.631 0.0371952ZM147.08 13.2528C147.109 9.76972 145.749 8.434 138.298 8.98329C138.298 8.98329 138.422 13.2528 138.452 17.6302C145.863 17.6664 147.015 17.0623 147.08 13.2528Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "ALogo",
};
</script>

<style lang="scss" scoped></style>
