<template>
  <div
    class="m-media-planning-home position-fixed d-flex align-items-center w-auto"
  >
    <MMediaPlanningCard
      class="m-media-planning-card-home rounded-0 card-rounded-sm-right shadow"
      style="max-width: calc(100vh - 300px)"
    >
      <template #header>
        <div class="py-3 text-center text-sm-left">
          <div class="d-inline-block text-primary" style="width: 170px">
            <img :src="headerLogo" class="img-fluid" />
          </div>
        </div>
        <div class="lead text-center mb-3">
          {{ $t("Welcome") }}.<br />
          {{ $t("Start your media planning now") }}!
        </div>
      </template>
      <template #afterBody>
        <BCardImg
          :alt="welcomeCardImg.name || ''"
          :src="welcomeCardImg.url || ''"
          class="rounded-0"
        />
      </template>
      <template #footer>
        <BButton
          variant="primary"
          pill
          class="mt-2 py-2 px-3"
          @click="handleClickNew"
        >
          {{ $t("Start") }}
        </BButton>
      </template>
    </MMediaPlanningCard>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import welcomeCardImg from "@/assets/images/welcome-card.jpg";

import { MMediaPlanningCard } from "@molecules";

const { mapGetters: mapConfigGetters } = createNamespacedHelpers(
  "configuration"
);

export default {
  name: "OMediaPlanningHome",
  components: { MMediaPlanningCard },
  computed: {
    ...mapConfigGetters(["homepageImages", "headerLogo"]),
    welcomeCardImg() {
      let img = {
        name: "Welcome",
        url: welcomeCardImg,
      };
      if (this.homepageImages && this.homepageImages.length) {
        const index = Math.floor(Math.random() * this.homepageImages.length);
        if (this.homepageImages[index]) {
          img = this.homepageImages[index];
        }
      }
      return img;
    },
  },
  methods: {
    handleClickNew() {
      this.$router.push("media-planning");
    },
  },
};
</script>

<style lang="scss" scoped>
.m-media-planning-home {
  top: 0;
  bottom: 0;
  #app:not(.embeded) & {
    bottom: var(--footer-height);
  }
}
</style>
